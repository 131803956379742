@tailwind base;
@tailwind components;
@tailwind utilities;

/* Buttons */
.btn,
.btn-sm {
  @apply inline-flex items-center justify-center rounded-[10px] font-medium leading-snug transition duration-150 ease-in-out;
}

.btn {
  @apply px-8 py-3 shadow-lg;
}

.btn-sm {
  @apply px-4 py-2 shadow;
}

.btn-gradient {
  background: linear-gradient(132.59deg, #9b51e0 22.83%, #3081edcc 100%);
}

.box-background {
  background: linear-gradient(138.84deg,
      rgba(58, 129, 191, 0.3) 3.17%,
      rgba(65, 48, 90, 0.3) 96.21%);
}

.box-background-faded {
  background: linear-gradient(138.84deg,
      rgba(58, 129, 191, 0.08) 3.17%,
      rgba(65, 48, 90, 0.08) 96.21%);
}

:root {
  --gradient: linear-gradient(170deg,
      #fb37ff,
      #9b6fee00,
      #9b6fee00,
      #7b7fea00,
      #7b7fea00,
      #7b7fea00,
      rgba(27, 177, 222, 0.5),
      #1bb1de);
}

/* Define a class for the rounded colored border with gradient */
.rounded-gradient-border {
  --border-width: 1px;
  --border-radius: 35px;

  background: var(--gradient);
  border: 0;
  padding: 1px;
  position: relative;
  border-radius: var(--border-radius);
  z-index: 1;
}

.rounded-gradient-border::before {
  content: "";
  display: block;
  height: calc(100% - calc(var(--border-width) * 2));
  width: calc(100% - calc(var(--border-width) * 2));
  background: #1f1d2b;
  position: absolute;
  top: var(--border-width);
  left: var(--border-width);
  border-radius: calc(var(--border-radius) - var(--border-width));
  z-index: -1;
}

@media (min-width: 1024px) {
  .hero-home-grid {
    grid-template-columns: 1.2fr 1fr;
  }
}

.tooltip-parent {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.5s;
}

.tooltip {
  align-self: end;
  min-height: 0;
  transition: visibility 0.5s;
  visibility: hidden;
}

:checked~.demo07 {
  grid-template-rows: 1fr;
}

:checked+.demo07 .demo07__content {
  visibility: visible;
}

.wave {
  position: absolute;
  margin: 0px 0px -1px 0px;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.wave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 60px;
  transform: rotateY(180deg);
}

.getting-started-box {
  box-shadow:
    inset -3.01px 3.01px 3.01px 0px rgba(255, 255, 255, 0.39),
    inset 2.01px -2.01px 20px 0px rgba(214, 214, 214, 0.17);
  backdrop-filter: blur(37.21px);
}

html {
  background-color: #1f1d2b;
}

/* Hamburger button */
.hamburger svg>*:nth-child(1),
.hamburger svg>*:nth-child(2),
.hamburger svg>*:nth-child(3) {
  transform-origin: center;
  transform: rotate(0deg);
}

.hamburger svg>*:nth-child(1) {
  transition:
    y 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    opacity 0.1s ease-in;
}

.hamburger svg>*:nth-child(2) {
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger svg>*:nth-child(3) {
  transition:
    y 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    width 0.1s 0.25s ease-in;
}

.hamburger.active svg>*:nth-child(1) {
  opacity: 0;
  y: 11;
  transform: rotate(225deg);
  transition:
    y 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 0.1s 0.12s ease-out;
}

.hamburger.active svg>*:nth-child(2) {
  transform: rotate(225deg);
  transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.active svg>*:nth-child(3) {
  y: 11;
  transform: rotate(135deg);
  transition:
    y 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    width 0.1s ease-out;
}

/* Swiper nagivation buttons css */
.swiper-button-next,
.swiper-button-prev {
  color: white;
  width: 30px;
  height: 30px;
  margin-top: -15px;
}

.swiper-button-next {
  right: -10px;
}

.swiper-button-prev {
  left: -10px
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px;
}

@keyframes fillBorder {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.animate-fillBorder {
  animation-name: fillBorder;
  animation-fill-mode: forwards;
  /* Keep the end state */
  height: 100%;
  /* Make sure to set the height */
  transition: width 0s;
  /* Ensure no transition while animating */
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}